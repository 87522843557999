import React from "react";

export function NewLineToBr({children = ""}){
  return children.split('\n').reduce(function (arr,line) {
    return arr.concat(
      line,
      <br />
    );
  },[]);
}

const Table = ({ db, handleGetRoomCharges, setCurrentPage }) => {
  return (
    <div className="table-wrapper" id ="copyy">
      <table className="table">
        <tbody>
          {db.map((item) => (
            <tr
              key={Math.random()}
              onClick={() => {
                setCurrentPage(1);
                handleGetRoomCharges(
                  item.reservation_id,
                  item.account_id,
                  item.guest_name,
                  item.reservation_id
                );
              }}
            >
              <td>
                <p><NewLineToBr>{item.account_id}</NewLineToBr></p>
              </td>
              <td>
                
                <p>
                <NewLineToBr>{item.guest_name}</NewLineToBr>
                </p>
                
              </td>
              <td className="last-td">
                <p>{item.reservation_id}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
