import React, { useRef, useEffect } from "react";
import CustomButton from "./CustomButton";

import lottie from "lottie-web";
import successAnim from "../assets/success.json";

import { Route, useLocation } from "react-router-dom";
import { toBeDisabled } from "@testing-library/jest-dom/dist/matchers";


const SuccessPage = () => {
  const animRef = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: animRef.current,
      renderer: "svg",
      loop: false,
      autoplay: true,
      animationData: successAnim,
    });
  }, []);

  //Sucess posting
  document.getElementById("btnClick71").click()

  return (
    <>
      <div className="page">
        <div ref={animRef} className="lottie-anim" />
        <p>Success</p>
      </div>
      
    </>
  );
};

export default SuccessPage;
