import React from "react";

import loaderSvg from "../assets/puff.svg";

const ProceessingPage = () => {
  return (
    <div className="page">
      <img src={loaderSvg} alt="loader" />
      <p>Processing</p>
    </div>
  );
};

export default ProceessingPage;
